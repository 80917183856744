<template>
  <!-- FIXME looks like it's better to get pipelinesStore.getInspectionStats and two loadings from  store, but not from props -->
  <PipelinesMapmode
    v-if="mapStore.isMapMode"
    :pipelines="pipelinesStore.groupPipelines"
    :isLoadingPipelines="pipelinesStore.isLoadingPipelines"
    :isLoadingStats="pipelinesStore.isLoadingStats"
    :onSelectPipelineId="onSelectPipelineId"
    :getInspectionStats="pipelinesStore.getInspectionStats"
  />
  <Pipelines
    v-else
    :pipelines="pipelinesStore.groupPipelines"
    :isLoadingPipelines="pipelinesStore.isLoadingPipelines"
    :isLoadingStats="pipelinesStore.isLoadingStats"
    :onSelectPipelineId="onSelectPipelineId"
  />
</template>

<script>
import { Pipelines, PipelinesMapmode } from "./views";
import {
  useAuthStore,
  useGlobalStore,
  useMapStore,
  usePipelinesStore,
} from "@/store";

export default {
  components: { PipelinesMapmode, Pipelines },
  data() {
    return {
      requests: new Map(),
    };
  },

  setup() {
    return {
      pipelinesStore: usePipelinesStore(),
      authStore: useAuthStore(),
      mapStore: useMapStore(),
      globalStore: useGlobalStore(),
    };
  },

  methods: {
    onSelectPipelineId(pipelineId) {
      this.pipelinesStore.setSelectedPipelineId(pipelineId);
    },
  },

  watch: {
    "pipelinesStore.selectedPipeline"(pipeline) {
      this.globalStore.resetFilters();
      this.pipelinesStore.setSelectedInspectionId(pipeline?.inspections[0].id);
      if (pipeline) {
        this.pipelinesStore.getPipeComments(pipeline.id);
      }
    },

    "pipelinesStore.selectedInspectionId"(newId) {
      this.pipelinesStore.cancelRequests();
      if (newId) {
        this.pipelinesStore.getInspectionStats();
      }
    },
  },
};
</script>
