<template>
  <div class="container">
    <PipelinesTable
      :pipelines="pipelines"
      :onSelect="onSelectPipelineId"
      :selectedPipelineId="pipelinesStore.selectedPipelineId"
      :isLoading="isLoadingPipelines"
    />
    <PipelineOverview
      v-if="pipelinesStore.selectedPipelineId && !isLoadingPipelines"
      :key="pipelinesStore.selectedPipelineId"
      :isLoading="isLoadingStats"
    />
  </div>
</template>

<script>
import { usePipelinesStore } from "@/store";
import { PipelineOverview, PipelinesTable } from "./components";

export default {
  name: "PipelinesDashboard",
  setup() {
    return {
      pipelinesStore: usePipelinesStore(),
    };
  },
  components: {
    PipelinesTable,
    PipelineOverview,
  },
  props: {
    pipelines: {
      type: Array,
      default() {
        return [];
      },
    },
    isLoadingPipelines: Boolean,
    isLoadingStats: Boolean,
    onSelectPipelineId: Function,
  },
  computed: {},
};
</script>
