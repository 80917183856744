import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useToast } from "vue-toast-notification";
import jwt_decode from "jwt-decode";
import { auth0 } from "@/config/auth0";
import { unitsConverter } from "@/config/units";
import { userCommentsAPI } from "@/features/comments/api";
import { DefaultService } from "@/open-api-code/ili-api";
import { getDepth, getGeoTIFF } from "@/utils/geoTiffHelpers";
import { useAuthStore } from "./useAuthStore";

const toast = useToast();
const requests = new Map();

export const usePipelinesStore = defineStore("pipelines", () => {
  const authStore = useAuthStore();

  const isLoadingPipelines = ref(false);

  function setIsLoadingPipelines(isLoading) {
    isLoadingPipelines.value = isLoading;
  }
  async function getPipelines() {
    setIsLoadingPipelines(true);
    try {
      const token = await auth0.getAccessTokenSilently();
      if (token) {
        requests.set(
          "pipelinesRequest",
          DefaultService.readPipelinesInlineInspectionsPipelinesGroupGet(
            authStore.selectedGroup
          )
        );
        const pipelines = await requests.get("pipelinesRequest");
        setGroupPipelines(pipelines);
      } else {
        console.error("No token, try to log in!");
      }
    } catch (error) {
      if (error.name !== "CancelError") {
        console.error(error);
        toast.error(`Read Inspection Stats - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      setIsLoadingPipelines(false);
    }
  }

  const isLoadingStats = ref(false);

  function setIsLoadingStats(isLoading) {
    isLoadingStats.value = isLoading;
  }

  const groupPipelines = ref([]);

  function setGroupPipelines(pipelines) {
    groupPipelines.value = pipelines;
  }

  const selectedPipeline = computed(() =>
    groupPipelines.value.find(({ id }) => id === selectedPipelineId.value)
  );

  const selectedPipelineId = ref(null);

  function setSelectedPipelineId(pipeline) {
    selectedPipelineId.value = pipeline;
  }

  const pipelineElevation = ref([]);
  const isLoadingPipelineElevation = ref(false);

  const convertedPipelineElevation = computed(() => {
    if (pipelineElevation.value.length === 0) {
      return [];
    }

    return pipelineElevation.value.map((item) =>
      unitsConverter.instance.convert(item, "m")
    );
  });

  function setIsLoadingPipelineElevation(isLoading) {
    isLoadingPipelineElevation.value = isLoading;
  }
  function setPipelineElevation(elevation) {
    pipelineElevation.value = elevation;
  }
  async function getElevation() {
    try {
      setIsLoadingPipelineElevation(true);

      const controller = new AbortController();
      const stats = await requests.get("statsRequest");
      const elevation = await getGeoTIFF(
        stats.map_trace_geometry.coordinates,
        controller
      );

      setPipelineElevation(elevation);
      requests.set("geoTIFFRequest", controller);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingPipelineElevation(false);
    }
  }

  const comments = ref([]);

  function setComments(value) {
    comments.value = value;
  }
  function updateComment(comment) {
    const editedIndex = comments.value.findIndex(({ id }) => id === comment.id);
    if (editedIndex >= 0) {
      comments.value[editedIndex] = comment;
    } else {
      comments.value.push(comment);
    }
  }
  function removeComment(commentId) {
    comments.value = comments.value.filter(
      (comment) => comment.id !== commentId
    );
  }
  async function getPipeComments(pipelineId) {
    try {
      setComments([]);

      const comments = await userCommentsAPI.getAll(
        pipelineId,
        authStore.selectedGroup
      );

      setComments(comments);
    } catch (error) {
      if (error.name !== "CanceledError") {
        console.error(error);
        toast.error(`Read user comments - ${error.message}`, {
          position: "top-right",
        });
      }
    }
  }

  const selectedInspectionId = ref(null);

  function setSelectedInspectionId(inspection) {
    selectedInspectionId.value = inspection;
  }

  const selectedInspectionStats = ref(null);

  const convertedHeightProfileDistance = computed(() =>
    selectedInspectionStats.value.height_profile.distance.map((item) =>
      unitsConverter.instance.convert(item, "m")
    )
  );
  const convertedHeightProfileZ = computed(() =>
    selectedInspectionStats.value.height_profile.z.map((item) =>
      unitsConverter.instance.convert(item, "m")
    )
  );

  function setSelectedInspectionStats(stats) {
    selectedInspectionStats.value = stats;
  }
  async function getInspectionStats() {
    setIsLoadingStats(true);
    setSelectedInspectionStats(null);
    try {
      const token = await auth0.getAccessTokenSilently();
      if (token) {
        const payload = jwt_decode(token);
        const groupKey = "https://acqgroups.cloud/groups";
        if (Object.prototype.hasOwnProperty.call(payload, groupKey)) {
          requests.set(
            "statsRequest",
            DefaultService.readInspectionStatsInlineInspectionsInspectionStatsInspectionIdGroupGet(
              selectedInspectionId.value,
              authStore.selectedGroup
            )
          );
          const stats = await requests.get("statsRequest");

          setSelectedInspectionStats(stats);
          await getElevation();

          requests.delete("statsRequest");
        }
      }
    } catch (error) {
      if (error.name !== "CancelError") {
        console.error(error);
        toast.error(`Read Inspection Stats - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      setIsLoadingStats(false);
    }
  }

  const pipelineDepth = computed(() => {
    const elevation = pipelineElevation.value;
    const inspectionStats = selectedInspectionStats.value;

    if (elevation.length === 0 || !inspectionStats) {
      return [];
    }

    return getDepth(elevation, inspectionStats.height_profile.z);
  });
  const convertedPipelineDepth = computed(() => {
    if (pipelineDepth.value.length === 0) {
      return [];
    }

    return pipelineDepth.value.map((item) =>
      unitsConverter.instance.convert(item, "m")
    );
  });
  const heightRange = computed(() => {
    const yAxisValues = [
      ...convertedHeightProfileZ.value,
      ...convertedPipelineElevation.value,
      ...convertedPipelineDepth.value,
    ];

    const min = Math.min(...yAxisValues);
    const max = Math.max(...yAxisValues);

    return [min, max];
  });

  function cancelRequests() {
    if (requests.has("statsRequest")) {
      requests.get("statsRequest").cancel();
    }
    if (requests.has("geoTIFFRequest")) {
      requests.get("geoTIFFRequest").abort();
    }
  }

  return {
    isLoadingPipelines,
    isLoadingStats,
    groupPipelines,
    selectedPipelineId,
    pipelineElevation,
    isLoadingPipelineElevation,
    setIsLoadingPipelineElevation,
    comments,
    selectedInspectionId,
    selectedInspectionStats,
    selectedPipeline,
    convertedPipelineElevation,
    convertedPipelineDepth,
    heightRange,
    convertedHeightProfileDistance,
    convertedHeightProfileZ,
    pipelineDepth,
    setIsLoadingPipelines,
    setIsLoadingStats,
    setGroupPipelines,
    setSelectedPipelineId,
    setPipelineElevation,
    setComments,
    updateComment,
    removeComment,
    setSelectedInspectionId,
    setSelectedInspectionStats,
    getPipelines,
    getElevation,
    getPipeComments,
    getInspectionStats,
    cancelRequests,
  };
});
