<template>
  <div
    class="container-table"
    :class="{ 'max-height-72': !mapStore.isMapMode }"
  >
    <table class="table">
      <thead>
        <tr>
          <th v-if="showEdit"></th>
          <th v-if="showAnalyser"></th>
          <THead title="id"></THead>
          <THead title="inspection_date"></THead>
          <THead title="upload_by"></THead>
          <THead title="address" style="min-width: 140px"></THead>
          <THead title="material"></THead>
          <THead title="reason"></THead>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="scanner in sortedScanners"
          :key="scanner.id"
          :class="{
            active: scannerControlStore.activeScannerId === scanner.id,
          }"
          @click="handleTableRowClick(scanner)"
          @dblclick="$emit('scanner-edit', scanner.id)"
        >
          <td v-if="showEdit">
            <EditScannerButton
              @click.stop="$emit('scanner-edit', scanner.id)"
            />
          </td>
          <td v-if="showAnalyser">
            <v-btn
              size="x-small"
              variant="flat"
              color="#24e3c6"
              @click="goToAnalyser(scanner.id)"
              >Analyser</v-btn
            >
          </td>
          <td>{{ scanner.tag }}</td>
          <td>{{ scanner.inspection_date }}</td>
          <td>{{ scanner.user }}</td>
          <td>{{ scanner.address }}</td>
          <td>{{ scanner.material }}</td>
          <td>
            {{ $t(`pipe_scanner.edit_modal.form.reasons.${scanner.reason}`) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="sortedScanners.length === 0" class="blank">
      {{ $t("common.no_items") }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

import { THead } from "@/components";
import EditScannerButton from "@/pages/scanner-control/components/EditScannerButton.vue";

import { getNextSortingOrder, getSortedArray } from "@/utils/tableHelpers.js";
import { useAuthStore, useMapStore, useScannerControlStore } from "@/store";

export default {
  name: "AnalysisScannersTable",

  emits: ["scanner-edit", "scanner-select"],

  components: { THead, EditScannerButton },

  provide() {
    return {
      sortedBy: computed(() => this.sortedBy),
      sortFunction: this.setSortedBy,
      localePath: "pipe_scanner.table_titles.",
    };
  },

  setup() {
    return {
      mapStore: useMapStore(),
      authStore: useAuthStore(),
      scannerControlStore: useScannerControlStore(),
    };
  },

  data: () => ({
    sortedBy: {
      keyName: null,
      order: "asc",
    },
  }),

  computed: {
    showEdit() {
      return this.authStore.userPermissions.includes("edit:upload");
    },

    showAnalyser() {
      return this.authStore.userPermissions.includes("use:analyser");
    },

    sortedScanners() {
      return getSortedArray(
        this.scannerControlStore.filteredAnalysisPendingScanners,
        this.sortedBy.order,
        this.sortedBy.keyName
      );
    },
  },

  methods: {
    setSortedBy(key) {
      const sortedBy = getNextSortingOrder(this.sortedBy, key);
      this.sortedBy = sortedBy;
    },

    goToAnalyser(id) {
      this.$router.push(`/scanner-control/${id}/analyser`);
    },

    handleTableRowClick(scanner) {
      this.scannerControlStore.toggleActiveScannerId(scanner.id);
      this.$emit("scanner-select", scanner);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-table {
  &.max-height-72 {
    max-height: 72vh !important;
  }

  td:first-child {
    padding-right: 0 !important;
    .mdi-square-edit-outline {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }

  .blank {
    text-align: center;
    letter-spacing: 0.5px;
    padding: 15px 0;
    width: 100%;
    cursor: default;
    font-weight: 700;
    color: gray;
  }
}
</style>
