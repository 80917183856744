<template>
  <template v-if="!shouldRedirectToDashboard">
    <AnomaliesMapmode v-if="mapStore.isMapMode" />
    <Anomalies v-else />
  </template>
</template>

<script>
import { computed } from "vue";
import { Anomalies, AnomaliesMapmode } from "./views";
import { useAnomaliesStore, useMapStore, usePipelinesStore } from "@/store";

export default {
  name: "AnomaliesPage",
  components: {
    Anomalies,
    AnomaliesMapmode,
  },
  setup() {
    return {
      pipelinesStore: usePipelinesStore(),
      mapStore: useMapStore(),
      anomaliesStore: useAnomaliesStore(),
    };
  },
  provide() {
    // TODO check if may remove it and use store
    return {
      hasActiveFilters: computed(() => this.anomaliesStore.hasActiveFilters), // WidgetBar
      onGenerateDigUp: this.anomaliesStore.getDigupSheet, // ContextMenu + DigUpTableBtn
      cancelDigUp: () => {
        this.anomaliesStore.checkedAnomaliesSelector.clear();
      }, // ContextMenu
      isAwaitingDigup: computed(() => this.anomaliesStore.isAwaitingDigup), // DigUpTableBtn + DigUpLoader
    };
  },

  mounted() {
    if (this.shouldRedirectToDashboard) {
      this.$router.push({ name: "dashboard" });
    } /* if (this.anomaliesStore.anomalies.length === 0) */ else {
      this.anomaliesStore.getAnomalies();
    }
  },

  beforeUnmount() {
    this.anomaliesStore.cancellAllRequests();
  },

  computed: {
    shouldRedirectToDashboard() {
      return !this.pipelinesStore.selectedInspectionId;
    },
  },
};
</script>
