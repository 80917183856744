<template>
  <ScannerControlMapmode v-if="mapStore.isMapMode" />
  <ScannerControl v-else />
</template>

<script>
import { computed } from "vue";

import { ScannerControl, ScannerControlMapmode } from "./views";
import { ScannerType } from "./config";
import {
  useMapStore,
  usePipeScannersStore,
  useScannerControlStore,
} from "@/store";

export default {
  name: "ScannerControlPage",

  components: {
    ScannerControl,
    ScannerControlMapmode,
  },

  setup() {
    return {
      mapStore: useMapStore(),
      pipeScannersStore: usePipeScannersStore(),
      scannerControlStore: useScannerControlStore(),
    };
  },

  provide() {
    return {
      hasActiveFilters: computed(
        () => this.scannerControlStore.hasFilteredScanners
      ),
    };
  },

  mounted() {
    if (
      this.pipeScannersStore.pipeScanners[ScannerType.Results].length === 0 ||
      this.pipeScannersStore.pipeScanners[ScannerType.InfoPending].length ===
        0 ||
      this.pipeScannersStore.pipeScanners[ScannerType.AnalysisPending]
        .length === 0
    ) {
      this.scannerControlStore.fetchPipeScanners();
    }
  },

  beforeUnmount() {
    this.pipeScannersStore.cancelAllRequests();
    this.scannerControlStore.cancelAllRequests();
  },
};
</script>
